import { useState } from 'react';
import { Navbar, Nav, Container, Image, Dropdown } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import { toast } from 'react-toastify';
import { RoutesConstants } from '../../routes/RoutesConstants';
import Modal from '../modal/CustomModal';
import { modalMessages, ValidationMessage } from '../../constants/appStrings';
import { Icons } from '../../constants/icons';

function Header() {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const [showModal, setShowModal] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate(RoutesConstants.LOGIN);
    } catch {
      toast.error(ValidationMessage.logoutError);
    } finally {
      setShowModal(false);
    }
  };

  return (
    <>
      <Navbar expand="lg" className="admin-navbar">
        <Container fluid>
          <Navbar.Brand as={Link} to={RoutesConstants.USER_MANAGEMENT}>
            <Image src={Icons.logoBlack} className="bg-img" />
          </Navbar.Brand>
          <Nav>
            <Nav.Link
              className={
                location.pathname.startsWith(RoutesConstants.USER_MANAGEMENT)
                  ? 'active'
                  : ''
              }
              as={Link}
              to={RoutesConstants.USER_MANAGEMENT}
            >
              User Management
            </Nav.Link>
            <Nav.Link
              className={
                location.pathname.startsWith(RoutesConstants.FLAG_MANAGEMENT)
                  ? 'active'
                  : ''
              }
              as={Link}
              to={RoutesConstants.FLAG_MANAGEMENT}
            >
              Flag Management
            </Nav.Link>
          </Nav>
          <Dropdown align="end" className="admin-profile">
            <Dropdown.Toggle
              className="admin-profile-btn"
              id="dropdown-custom-1"
            >
              <Image src={Icons.adminProfile} className="admin-img" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="profile-dropdown">
              <Dropdown.Item
                className="signout-btn"
                onClick={() => setShowModal(true)}
              >
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </Navbar>

      {/* Confirmation Modal */}
      <Modal
        customClass=""
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalMessages.signOutTitle}
        bodyContent={modalMessages.signOutMessage}
        footerContent={
          <>
            <button className="btn btn-danger" onClick={handleLogout}>
              yes, sign out
            </button>
            <button
              className="btn btn-transparent"
              onClick={() => setShowModal(false)}
            >
              not now
            </button>
          </>
        }
      />
    </>
  );
}

export default Header;
