export const Regex = {
  FILE_SIZE: 512000,
  emailRegex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$/,
  password: /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
  nameRegex: /^[A-Za-z0-9_@!£$%*./#&+-]+$/,
  socialIdRegex: /^((?!www|http|https|.com).)*$/,
  userNameRegex: /^[a-zA-Z0-9._]{2,24}\d*$/,
  websiteRegex:
    /^([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  numberRegex: /^[0-9]*$/,
};
