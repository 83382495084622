export enum ValidationMessage {
  emailPattern = 'Enter a valid Email Address.',
  passwordRequired = 'Password is required.',
  passwordPattern = 'Password does not meet the required criteria.',
  websitePattern = 'Enter a valid URL.',
  email = 'Email Address is required.',
  confirmPassword = 'Confirm Password is required.',
  passwordNotMatched = 'Confirm Password does not match.',
  accessDenied = 'Access denied: Only admin user can sign in.',
  invalidEmailPassword = 'Invalid Email Address or Password.',
  tooManyRequest = 'Too many requests. Try again later.',
  passwordResetLinkSent = 'Password reset link has been sent to your email. Please check your inbox.',
  noAccountFound = 'No account found of this email.',
  errorInSendingResetLink = 'Error sending reset link. Please try again.',
  invalidOrExpiredResetLink = 'Invalid or expired password reset link. You can send new reset link.',
  resetLinkExpired = 'Invalid or expired password reset link. Please try again with a valid link or request a new one.',
  noResetLinkFound = 'No reset link found.',
  passwordResetSuccessfully = 'Password has been reset successfully. You can now Sign In.',
  logoutError = 'Error logging out. Please try again.',
}

export const ClassString = {
  inputError: 'input-error',
  passwordInput: 'password-input',
};

export const modalMessages = {
  deleteConfirmation: (value: string) =>
    `Are you sure you want to delete this ${value}? Once deleted, it cannot be restored.`,
  userActivationMessage: (modalAction: any) =>
    `Are you sure you want to ${
      modalAction?.isActive === false ? 'activate' : 'deactivate'
    } this user?`,
  statusConfirmationMessage: (modalAction: any) =>
    `Are you sure you want to ${
      modalAction?.status === 'unresolved' ? 'resolve' : 'unresolve'
    } this flag?`,
  signOutMessage: 'Are you sure you want to sign out of the platform?',
  signOutTitle: 'Sign Out?',
};

export const USER_TYPES = {
  CHARACTER_AND_DM: 'Character + Dungeon Master',
  DUNGEON_MASTER: 'Dungeon Master',
  CHARACTER: 'Character',
  NONE: '',
};

export const APP_STRING = {
  NO_RESULT_FOUND: 'No result found!',
  NOT_FOUNT_SEARCHED_DATA:
    'We couldn’t find what you search for. Try searching again.',
};
