import { FC } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import { Report } from '../../../services/flag.service';
import { capitalize } from '../../../utils/appUtils';
import NumberPagination from '../../../components/pagination/NumberPagination';
import { AppNumbers as num } from '../../../constants/appNumbers';
import { Icons } from '../../../constants/icons';
import NoResultFound from '../../../components/noResultFound/NoResultFound';

interface FlagListProps<T> {
  data: T[];
  currentPage: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  showDescriptionModal: (flag: Report) => void;
  onSort: (column: keyof Report) => void;
  showStatusConfirmModal: (id: string, status: string) => void;
  loading: boolean;
}

const FlagList: FC<FlagListProps<any>> = ({
  data,
  currentPage,
  itemsPerPage,
  onPageChange,
  showDescriptionModal,
  showStatusConfirmModal,
  onSort,
  loading,
}) => {
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div className="admin-table-wrap">
      {currentItems.length > num.ZERO ? (
        <table>
          <thead>
            <tr>
              <th className="wid-20">UserName</th>
              <th className="wid-20">Reported By</th>
              <th className="wid-20" onClick={() => onSort('createdAt')}>
                <span className="th-sort">Reported ON</span>
              </th>
              <th className="wid-20">Reasons</th>
              <th className="wid-10" onClick={() => onSort('status')}>
                <span className="th-sort">Status</span>
              </th>
              <th className="wid-10 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((flag) => (
              <tr key={flag.id}>
                <td className="wid-20">
                  <div className="user-profile">
                    <div
                      className={`user-img ${
                        !flag.reportedRefUserImageUrl && 'p-2'
                      }`}
                    >
                      <Image
                        src={flag.reportedRefUserImageUrl || Icons.dummyUser}
                        alt="image"
                      />
                    </div>
                    <div className="user-names">{flag.reportedRefUserName}</div>
                  </div>
                </td>
                <td className="wid-20">
                  <div className="user-profile">
                    <div
                      className={`user-img ${
                        !flag.reportedRefUserImageUrl && 'p-2'
                      }`}
                    >
                      <Image
                        src={flag.reportedByUserImageUrl || Icons.dummyUser}
                        alt="image"
                      />
                    </div>
                    <div className="user-names">{flag.reportedByUserName}</div>
                  </div>
                </td>
                <td className="wid-20">
                  {flag.createdAt
                    ? flag.createdAt.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })
                    : ''}
                </td>
                <td
                  className="wid-20 text-underline"
                  onClick={() => showDescriptionModal(flag)}
                >
                  {flag.category}
                </td>
                <td
                  className={`wid-10 ${
                    flag.status === 'unresolved' ? '' : 'text-success'
                  }`}
                >
                  {capitalize(flag.status)}
                </td>
                <td className="wid-10">
                  <Dropdown align="end" className="table-dropdown">
                    <Dropdown.Toggle
                      className="action-btn"
                      id="dropdown-custom-1"
                    >
                      <Image src={Icons.actionIcon} alt="icon" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="action-dropdown">
                      {flag.status === 'unresolved' ? (
                        <Dropdown.Item
                          className="text-success"
                          onClick={() =>
                            showStatusConfirmModal(flag.id, flag.status)
                          }
                        >
                          Mark as Resolved
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          onClick={() =>
                            showStatusConfirmModal(flag.id, flag.status)
                          }
                        >
                          Mark as Unresolved
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading && <NoResultFound />
      )}

      <NumberPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
        pageRange={num.TWO}
      />
    </div>
  );
};

export default FlagList;
