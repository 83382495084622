import React, { ReactNode } from 'react';
import { Button, Spinner, ButtonProps } from 'react-bootstrap';

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
  buttonText?: string;
  spinnerProps?: React.ComponentProps<typeof Spinner>;
  children?: ReactNode;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading = false,
  buttonText = 'Submit',
  spinnerProps = {},
  children,
  ...buttonProps
}) => {
  return (
    <Button {...buttonProps} disabled={loading || buttonProps.disabled}>
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          {...spinnerProps}
          className="me-2" // Adds spacing between spinner and text
        />
      ) : (
        children || buttonText
      )}
    </Button>
  );
};

export default LoadingButton;
