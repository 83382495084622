import { FC } from 'react';
import NumberPagination from '../../../components/pagination/NumberPagination';
import { Dropdown, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../../routes/RoutesConstants';
import { User } from '../../../services/user.service';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { AppNumbers as num } from '../../../constants/appNumbers';
import { Icons } from '../../../constants/icons';
import NoResultFound from '../../../components/noResultFound/NoResultFound';
import { APP_STRING } from '../../../constants/appStrings';
import { formatPhoneNumber } from '../../../utils/appUtils';

interface UserListProps<T> {
  data: T[];
  currentPage: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  onShowModal: (userId: string, isActive: undefined | boolean) => void;
  onSort: (column: keyof User) => void; // Updated to match User keys
  loading: boolean;
}

const UserList: FC<UserListProps<any>> = ({
  data,
  currentPage,
  itemsPerPage,
  onPageChange,
  onShowModal,
  onSort,
  loading,
}) => {
  const navigate = useNavigate();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div className="admin-table-wrap">
      {currentItems.length > num.ZERO ? (
        <table>
          <thead>
            <tr>
              <th className="wid-15" onClick={() => onSort('name')}>
                <span className="th-sort">UserName</span>
              </th>
              <th className="wid-15">Phone Number</th>
              <th className="wid-15" onClick={() => onSort('createdAt')}>
                <span className="th-sort">Registered on</span>
              </th>
              <th className="wid-15" onClick={() => onSort('userType')}>
                <span className="th-sort">User Type</span>
              </th>
              <th className="wid-10">Zip Code</th>
              <th className="wid-15">Game Preferences</th>
              <th className="wid-10" onClick={() => onSort('isActive')}>
                <span className="th-sort">Status</span>
              </th>
              <th className="wid-5">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((user) => (
              <tr key={user.id}>
                <td className="wid-15">
                  <div className="user-profile">
                    <div
                      className={`user-img ${!user.profileImageUrl && 'p-2'}`}
                    >
                      <Image src={user.profileImageUrl || Icons.dummyUser} />
                    </div>
                    <div
                      className="user-name"
                      onClick={() =>
                        navigate(
                          `${RoutesConstants.USER_MANAGEMENT}/${user.id}`
                        )
                      }
                    >
                      {user.name || '-'}
                    </div>
                  </div>
                </td>
                <td className="wid-15">{formatPhoneNumber(user.phone)}</td>
                <td className="wid-15">
                  {user.createdAt
                    ? user.createdAt.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })
                    : '-'}
                </td>
                <td className="wid-15">{user.userType || '-'}</td>
                <td className="wid-10">{user.zipCode || '-'}</td>
                <td className="wid-15">{user.gamePreferences || '-'}</td>
                <td
                  className={`wid-10 ${
                    user.isActive ? 'text-success' : 'text-danger'
                  }`}
                >
                  {user.isActive ? 'Active' : 'Inactive'}
                </td>
                <td className="wid-5">
                  <Dropdown align="end" className="table-dropdown">
                    <Dropdown.Toggle
                      className="action-btn"
                      id="dropdown-custom-1"
                    >
                      <Image src={Icons.actionIcon} alt="icon" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="action-dropdown">
                      {user.isActive ? (
                        <Dropdown.Item
                          className="text-danger"
                          onClick={() => onShowModal(user.id, user.isActive)}
                        >
                          Deactivate User
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          onClick={() => onShowModal(user.id, user.isActive)}
                        >
                          Activate User
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading && (
          <NoResultFound
            title={APP_STRING.NO_RESULT_FOUND}
            subtitle={APP_STRING.NOT_FOUNT_SEARCHED_DATA}
          />
        )
      )}

      <NumberPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
        pageRange={num.TWO}
      />
    </div>
  );
};

export default UserList;
