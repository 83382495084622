export enum AppNumbers {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
  HUNDRED = 100,
  TWO_HUNDRED = 200,
  THREE_HUNDRED = 300,
  FOUR_HUNDRED = 400,
  FOUR_HUNDRED_ONE = 401,
  FOUR_HUNDRED_THREE = 403,
  FOUR_HUNDRED_FOUR = 404,
  FIVE_HUNDRED = 500,
  ONE_K = 1000,
  TWO_K = 2000,
  THREE_K = 3000,
  FIVE_THOUSAND = 5000,
  TEN_K = 10000,
  TWENTY_K = 20000,
  THIRTY_K = 30000,
}
