import { db } from '../firebaseConfig';
import {
  collection,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  Timestamp,
} from 'firebase/firestore';

export interface Report {
  id: string;
  reportedBy: string;
  reportedRef: string;
  reportedByUserName: string;
  reportedRefUserName: string;
  reportedByUserImageUrl: string;
  reportedRefUserImageUrl: string;
  status: string;
  createdAt: string;
  attachments: string;
  description: string;
}

class FlagDataService {
  readonly reportCollectionRef = collection(db, 'report');

  updateReport = (id: string, updatedReport: any) => {
    const reportDoc = doc(db, 'report', id);
    return updateDoc(reportDoc, updatedReport);
  };

  getAllReports = () => {
    return getDocs(this.reportCollectionRef);
  };

  getReport = (id: string) => {
    const reportDoc = doc(db, 'report', id);
    return getDoc(reportDoc);
  };

  // Fetch a user's data by ID
  getUserById = async (userId: string) => {
    const userDoc = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userDoc);
    return userSnapshot.exists() ? userSnapshot.data() : null;
  };

  // Fetch all reports and merge user data
  getAllReportsWithUserDetails = async () => {
    const reportsSnapshot = await this.getAllReports();
    const reports: Report[] = reportsSnapshot.docs.map((docData) => ({
      ...docData.data(),
      id: docData.id,
      createdAt:
        docData.data().createdAt instanceof Timestamp
          ? docData.data().createdAt.toDate()
          : null,
    })) as Report[];

    return Promise.all(
      reports.map(async (report) => {
        const reportedByUser = await this.getUserById(report.reportedBy);
        const reportedRefUser = await this.getUserById(report.reportedRef);

        return {
          ...report,
          reportedByUserName: reportedByUser?.name || '',
          reportedRefUserName: reportedRefUser?.name || '',
          reportedByUserImageUrl: reportedByUser?.profileImageUrl || '',
          reportedRefUserImageUrl: reportedRefUser?.profileImageUrl || '',
        };
      })
    );
  };

  toggleIsResolved = async (id: string, status: string) => {
    const userDoc = doc(db, 'report', id);
    const updatedStatus = status === 'unresolved' ? 'resolved' : 'unresolved';

    // Update the user's isActive status in the user document
    await updateDoc(userDoc, { status: updatedStatus });

    return updatedStatus;
  };
}

// Export Singleton Instance
const flagDataServiceInstance = new FlagDataService();
export default flagDataServiceInstance;
