import { FC, useState } from 'react';
import { Image } from 'react-bootstrap';
import Modal from '../../../components/modal/CustomModal';
import { AppNumbers as num } from '../../../constants/appNumbers';
import { Icons } from '../../../constants/icons';
import { modalMessages } from '../../../constants/appStrings';
import NoProfile from './NoProfile';

interface UserProfileInfoProps<T> {
  profileData: T[] | null;
  onDeleteProfile: (documentId: string) => void;
  loading: boolean;
}

const UserProfileInfo: FC<UserProfileInfoProps<any>> = ({
  profileData,
  onDeleteProfile,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<any | null>(null);

  const confirmDelete = () => {
    if (selectedProfile) {
      onDeleteProfile(selectedProfile.documentId);
      setShowModal(false);
      setSelectedProfile(null);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedProfile(null);
  };

  if (!profileData || profileData.length === num.ZERO) {
    return <NoProfile />;
  }

  return (
    <>
      {profileData.map((profile, index) => (
        <div className="user-char-col" key={index}>
          <div className="char-card-title">
            {profile.type === 'Character'
              ? 'Character Info'
              : 'Dungeon Master Info'}
          </div>
          <div className="user-char-card">
            <div className="char-img">
              <Image
                src={
                  profile.type === 'Character'
                    ? profile.characterProfileImage || Icons.dummyUser
                    : profile.dmProfileImage || Icons.dummyUser
                }
                alt={`${profile.type} Profile`}
              />
            </div>
            <div className="w-100">
              <div className="char-info-col">
                <div className="char-info-label">Name</div>
                <div className="char-info-data">
                  {profile.name || profile.campaignName}
                </div>
              </div>
              <div className="char-info-row">
                {profile.type === 'Character' ? (
                  <>
                    <div className="char-info-col">
                      <div className="char-info-label">Game Preferences</div>
                      <div className="char-info-data">{profile.gameStyle}</div>
                    </div>
                    <div className="char-info-col">
                      <div className="char-info-label">Type</div>
                      <div className="char-info-data">{profile.class}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="char-info-col">
                      <div className="char-info-label">Game Preferences</div>
                      <div className="char-info-data">{profile.gameStyle}</div>
                    </div>
                    <div className="char-info-col">
                      <div className="char-info-label">Experience</div>
                      <div className="char-info-data">
                        {profile.experienceAsDM}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="char-info-col mb-0">
                <div className="char-info-label">Attributes</div>
                <div className="char-info-data">
                  {profile.type === 'Character'
                    ? profile.backstory
                    : profile.aboutCampaign}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Confirmation delete profile */}
      {selectedProfile && (
        <Modal
          customClass={'char-modal'}
          show={showModal}
          onHide={closeModal}
          title={`Delete ${
            selectedProfile.type === 'Character'
              ? 'Character'
              : 'Dungeon Master'
          }?`}
          bodyContent={modalMessages.deleteConfirmation(selectedProfile.type)}
          footerContent={
            <>
              <button className="btn btn-danger" onClick={confirmDelete}>
                Yes, delete
              </button>
              <button className="btn btn-transparent" onClick={closeModal}>
                Not now
              </button>
            </>
          }
        />
      )}
    </>
  );
};

export default UserProfileInfo;
