import React from 'react';
import { Pagination } from 'react-bootstrap';
import { AppNumbers as num } from '../../constants/appNumbers';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  pageRange?: number; // Optional prop for controlling the range of displayed pages
}

const NumberPagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  pageRange = num.TWO,
}) => {
  const startPage = Math.max(num.ONE, currentPage - pageRange);
  const endPage = Math.min(totalPages, currentPage + pageRange);

  return (
    <Pagination>
      {/* "Previous" button */}
      {currentPage > num.ONE && (
        <Pagination.Prev
          className="page-prev"
          onClick={() => onPageChange(currentPage - num.ONE)}
        />
      )}

      {/* Page number buttons */}
      {Array.from(
        { length: endPage - startPage + num.ONE },
        (_, index) => startPage + index
      ).map((page) => (
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => onPageChange(page)}
        >
          {page}
        </Pagination.Item>
      ))}

      {/* "Next" button */}
      {currentPage < totalPages && (
        <Pagination.Next
          className="page-next"
          onClick={() => onPageChange(currentPage + num.ONE)}
        />
      )}
    </Pagination>
  );
};

export default NumberPagination;
