export const InitialValues = {
  login: {
    email: '',
    password: '',
  },
  forgotPassword: {
    email: '',
  },
  resetPassword: {
    password: '',
    confirmPassword: '',
  },
};
