import { db } from '../firebaseConfig';
import { AppNumbers as num } from '../constants/appNumbers';

import {
  collection,
  getDocs,
  getDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
  Timestamp,
  DocumentData,
} from 'firebase/firestore';
import { USER_TYPES } from '../constants/appStrings';

export interface User {
  id: string;
  name: string;
  gender: string;
  phone: string;
  zipCode: string;
  profileImageUrl: string;
  isProfileCompleted: boolean;
  userType: string;
  gamePreferences: string;
  isActive: undefined | boolean;
  personalityTags: [];
  createdAt: Date | null;
  profile: DocumentData[] | null;
}

function getUserType(
  isDmProfileCompleted: boolean,
  isCharacterProfileCompleted: boolean
): string {
  if (isDmProfileCompleted && isCharacterProfileCompleted) {
    return USER_TYPES.CHARACTER_AND_DM;
  } else if (isDmProfileCompleted) {
    return USER_TYPES.DUNGEON_MASTER;
  } else if (isCharacterProfileCompleted) {
    return USER_TYPES.CHARACTER;
  }

  return USER_TYPES.NONE;
}

function getGamePreferences(profiles: any[]): string {
  // Extract unique game preferences
  const uniquePreferences = Array.from(
    new Set(profiles.map((profile) => profile.gameStyle))
  );

  // Join preferences with " + " if there are multiple
  return uniquePreferences.join(' + ');
}

class UserDataService {
  readonly userCollectionRef = collection(db, 'users');

  deleteProfile = (documentId: string) => {
    const profileDoc = doc(db, 'profile', documentId);
    return deleteDoc(profileDoc);
  };

  fetchUsers = async (): Promise<User[]> => {
    try {
      const querySnapshot = await getDocs(this.userCollectionRef);

      // Fetch all matching profiles for each user
      const profilePromises = querySnapshot.docs.map(async (docData) => {
        const userId = docData.id;
        const profileQuery = query(
          collection(db, 'profile'),
          where('createdBy', '==', userId)
        );
        const profileSnapshot = await getDocs(profileQuery);

        return {
          userId,
          profiles: profileSnapshot.docs.map((profileDoc) => profileDoc.data()),
        };
      });

      const profileResults = await Promise.all(profilePromises);

      // Immediately return the mapped user data merged with profile data
      return querySnapshot.docs.map((docData) => {
        const data = docData.data();
        const createdAt = data.createdAt;
        const profileResult = profileResults.find(
          (result) => result.userId === docData.id
        );
        const profiles = profileResult?.profiles || [];

        return {
          id: docData.id,
          name: data.name,
          gender: data.gender,
          phone: data.phone,
          zipCode: data.zipCode,
          profileImageUrl: data.profileImageUrl,
          isProfileCompleted: data.isProfileCompleted,
          userType: getUserType(
            data.isDmProfileCompleted,
            data.isCharacterProfileCompleted
          ),
          gamePreferences:
            profiles.length > num.ZERO ? getGamePreferences(profiles) : '',
          isActive: data.isActive !== undefined ? data.isActive : true,
          personalityTags: data.personalityTags,
          createdAt: createdAt instanceof Timestamp ? createdAt.toDate() : null,
          profile: profiles.length > num.ZERO ? profiles : null,
        };
      });
    } catch {
      return [];
    }
  };

  fetchUserById = async (userId: string): Promise<User | null> => {
    try {
      // Fetch the user's document from the user collection
      const userDocRef = doc(this.userCollectionRef, userId);
      const userDocSnapshot = await getDoc(userDocRef);

      if (!userDocSnapshot.exists()) {
        return null;
      }

      // Fetch matching profiles for the user
      const profileQuery = query(
        collection(db, 'profile'),
        where('createdBy', '==', userId)
      );
      const profileSnapshot = await getDocs(profileQuery);

      // Map profile data
      const profiles = profileSnapshot.docs.map((profileDoc) =>
        profileDoc.data()
      );

      // Extract user data
      const userData = userDocSnapshot.data();
      const createdAt = userData.createdAt;

      return {
        id: userId,
        name: userData.name,
        gender: userData.gender,
        phone: userData.phone,
        zipCode: userData.zipCode,
        profileImageUrl: userData.profileImageUrl,
        isProfileCompleted: userData.isProfileCompleted,
        userType: getUserType(
          userData.isDmProfileCompleted,
          userData.isCharacterProfileCompleted
        ),
        gamePreferences:
          profiles.length > num.ZERO ? getGamePreferences(profiles) : '',
        isActive: userData.isActive !== undefined ? userData.isActive : true,
        personalityTags: userData.personalityTags,
        createdAt: createdAt instanceof Timestamp ? createdAt.toDate() : null,
        profile: profiles.length > num.ZERO ? profiles : null,
      };
    } catch {
      return null;
    }
  };

  toggleIsActive = async (id: string, currentStatus: boolean | undefined) => {
    const userDoc = doc(db, 'users', id);
    const updatedStatus = currentStatus === undefined ? false : !currentStatus;

    // Check if the user is being deactivated
    if (updatedStatus === false) {
      // Fetch profiles from the "profile" collection where createdBy matches the user ID
      const profileQuery = query(
        collection(db, 'profile'),
        where('createdBy', '==', id)
      );
      const profileSnapshot = await getDocs(profileQuery);

      // Check if any profile has type "Character"
      const characterProfile = profileSnapshot.docs.find((profileDoc) => {
        const profileData = profileDoc.data();
        return profileData.type === 'Character';
      });

      if (characterProfile) {
        // If a "Character" profile exists, update the isCharPartyready field in the profile document
        const characterProfileDoc = doc(db, 'profile', characterProfile.id); // Use profileDoc.id to reference the correct profile document
        await updateDoc(characterProfileDoc, { isCharPartyready: false });
      }
    }

    // Update the user's isActive status in the user document
    await updateDoc(userDoc, { isActive: updatedStatus });
    return updatedStatus;
  };
}

// Export Singleton Instance
const userDataServiceInstance = new UserDataService();
export default userDataServiceInstance;
