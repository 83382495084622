import { Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Header from '../components/header/Header';
import { RoutesConstants } from './RoutesConstants';
import Loader from '../components/loader/Loader';

function ProtectedRoute({ children, ...rest }) {
  const { user, loading } = useAuth();

  if (loading) return <Loader />;

  return user ? (
    <Fragment>
      <Header {...rest} />
      {children}
    </Fragment>
  ) : (
    <Navigate to={RoutesConstants.LOGIN} />
  );
}

export default ProtectedRoute;
