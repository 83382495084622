import React from 'react';
import { Image } from 'react-bootstrap';
import { Icons } from '../../../constants/icons';

const NoProfile = () => {
  return (
    <div className="user-char-card no-char-data">
      <div className="no-char-col">
        <div className="no-char-img">
          <Image src={Icons.noCharImg} alt="icon" />
        </div>
        <div className="no-char-title">No character & DM profile.</div>
        <div className="no-char-subtitle">
          The character & DM profile has not been created yet. It is currently
          unavailable.
        </div>
      </div>
    </div>
  );
};

export default NoProfile;
