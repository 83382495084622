import { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { sendPasswordResetEmail } from 'firebase/auth';
import { ErrorMessage, Field, Formik } from 'formik';
import { forgotPasswordSchema } from '../../utils/validationSchema';
import { InitialValues } from '../../constants/formInitialValues';
import { ClassString, ValidationMessage } from '../../constants/appStrings';
import AuthLayout from './AuthLayout';
import { Link } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import { toast } from 'react-toastify';
import useDisallowFirstSpace from '../../hooks/useDisallowFirstSpace';
import { AppNumbers as num } from '../../constants/appNumbers';
import { RoutesConstants } from '../../routes/RoutesConstants';
import LoadingButton from '../../components/button/Button';

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  useDisallowFirstSpace(inputRef);

  const handleInput =
    (setFieldValue: any, fieldName: string) => (event: any) => {
      let { value } = event.target;

      if (value.startsWith(' ')) {
        value = value.trimStart();
      }

      // Update Formik field value
      setFieldValue(fieldName, value);
    };

  const submitForm = async (values: any, { resetForm }: any) => {
    setLoading(true);
    try {
      // Send password reset email using Firebase
      await sendPasswordResetEmail(auth, values.email);
      toast.success(ValidationMessage.passwordResetLinkSent);
      resetForm();
    } catch (error: any) {
      if (error.code === 'auth/user-not-found') {
        toast.error(ValidationMessage.noAccountFound);
      } else {
        toast.error(ValidationMessage.errorInSendingResetLink);
      }
    }
    setLoading(false);
  };

  return (
    <AuthLayout title="Forgot Password">
      <div className="form-subtitle">
        Enter your registered email address to reset your password.
      </div>
      <Formik
        validationSchema={forgotPasswordSchema}
        onSubmit={submitForm}
        initialValues={InitialValues.forgotPassword}
      >
        {({ handleSubmit, errors, touched, setFieldValue }) => (
          <Form className="login-form" noValidate onSubmit={handleSubmit}>
            <Form.Group
              controlId="formEmail"
              className={
                errors.email && touched.email
                  ? ClassString.inputError
                  : 'input-group'
              }
            >
              <Field
                type="email"
                placeholder="Email Address*"
                name="email"
                className="form-control"
                id="formEmail"
                maxLength={num.HUNDRED}
                innerRef={inputRef} // Attach the ref here to link it with the hook
                onInput={handleInput(setFieldValue, 'email')}
              />
              <ErrorMessage name="email" component="span" className="error" />
            </Form.Group>

            <LoadingButton
              loading={loading}
              buttonText="Send Link"
              variant="primary"
              type="submit"
            />
          </Form>
        )}
      </Formik>
      <div className="forgotPwd-link">
        <Link to={RoutesConstants.LOGIN}>Cancel</Link>
      </div>
    </AuthLayout>
  );
}

export default ForgotPassword;
