import { useState, useEffect } from 'react';
import UserList from './UserList';
import { Container, Dropdown, Image } from 'react-bootstrap';
import userDataServiceInstance, { User } from '../../../services/user.service';
import Loader from '../../../components/loader/Loader';
import CustomModal from '../../../components/modal/CustomModal';
import { exportUsersToCSV } from '../../../utils/appUtils';
import { AppNumbers as num } from '../../../constants/appNumbers';
import { Icons } from '../../../constants/icons';
import { modalMessages, USER_TYPES } from '../../../constants/appStrings';
import UserFilterModal from './UserFilterModal';

type SortOrder = 'asc' | 'desc';

const UserManagement = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [zipcodes, seZipcodes] = useState<string[]>([]);
  const [selectedZipCodes, setSelectedZipCodes] = useState<string[]>([]);
  const [selectedUserTypes, setSelectedUserTypes] = useState<string[]>([]);
  const [selectedGamePreferences, setSelectedGamePreferences] =
    useState<string>();
  const [isFiltered, setIsFiltered] = useState(false);
  const [currentPage, setCurrentPage] = useState(num.ONE);
  const [itemsPerPage] = useState(num.TEN);
  const [loading, setLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState<keyof User | null>('name');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

  // Filter Modal
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  const handleShowFilter = () => setShowFilter(true);

  // Activation/Deactivation Modal
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState<{
    userId: string;
    isActive: boolean | undefined;
  } | null>(null);

  const fetchUsers = async () => {
    try {
      const data = await userDataServiceInstance.fetchUsers();
      const uniqueZipCodes = Array.from(
        new Set(
          data
            .map((item) => item.zipCode) // Extract zipCode
            .filter((zipCode) => zipCode !== undefined && zipCode !== '') // Remove undefined and empty zip codes
        )
      );

      seZipcodes(uniqueZipCodes);
      setUsers(data);
    } catch (error) {
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleToggleStatus = async (userId: string, isActive = false) => {
    setLoading(true);
    try {
      await userDataServiceInstance.toggleIsActive(userId, isActive);
      fetchUsers();
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleShowModal = (userId: string, isActive = false) => {
    setModalAction({ userId, isActive });
    setShowModal(true);
  };

  const handleSort = (column: keyof User) => {
    if (sortColumn === column) {
      // Toggle sort order
      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      // Set new column and reset to ascending order
      setSortColumn(column);
      setSortOrder('asc');
    }

    // Reset to the first page
    setCurrentPage(num.ONE);
  };

  const getSortedData = (): User[] => {
    const dataToSort = filteredUsers.length > num.ZERO ? filteredUsers : users;

    // Check if filteredUsers is empty or if no valid filter is applied
    if (isFiltered && filteredUsers.length === 0) {
      return [];
    }

    if (!sortColumn || !users.some((user) => user.hasOwnProperty(sortColumn))) {
      return dataToSort;
    }

    const collator = new Intl.Collator(undefined, { sensitivity: 'base' });

    // Helper: Normalize strings for comparison
    const normalize = (str: string): string => str.trim().normalize('NFKC');

    // Helper: Handle null or undefined values
    const handleNullOrUndefined = (aValue: any, bValue: any): number => {
      if (aValue == null || bValue == null) {
        if (aValue == null && bValue == null) {
          return num.ZERO;
        }

        return aValue == null
          ? sortOrder === 'asc'
            ? num.ONE
            : -num.ONE
          : sortOrder === 'asc'
          ? -num.ONE
          : num.ONE;
      }
      return num.ZERO;
    };

    // Helper: Compare dates
    const compareDates = (aValue: any, bValue: any): number => {
      const parseDate = (value: any): number => {
        if (typeof value === 'string' || value instanceof Date) {
          const date = new Date(value);
          if (!isNaN(date.getTime())) {
            return date.getTime();
          }
        }
        return NaN;
      };

      const dateA = parseDate(aValue);
      const dateB = parseDate(bValue);

      if (isNaN(dateA) || isNaN(dateB)) {
        return handleNullOrUndefined(dateA, dateB);
      }

      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    };

    // Helper: Compare strings with secondary sorting
    const compareStrings = (
      aValue: string,
      bValue: string,
      a: User,
      b: User
    ): number => {
      const normalizedA = normalize(aValue);
      const normalizedB = normalize(bValue);

      const primary =
        sortOrder === 'asc'
          ? collator.compare(normalizedA, normalizedB)
          : collator.compare(normalizedB, normalizedA);

      if (primary === num.ZERO) {
        const secondaryA = normalize(a['name'] || '');
        const secondaryB = normalize(b['name'] || '');

        return (
          handleNullOrUndefined(secondaryA, secondaryB) ||
          collator.compare(secondaryA, secondaryB)
        );
      }

      return primary;
    };

    // Helper: Compare booleans
    const compareBooleans = (
      aValue: boolean,
      bValue: boolean,
      a: User,
      b: User
    ): number => {
      const primary =
        sortOrder === 'asc'
          ? Number(aValue) - Number(bValue)
          : Number(bValue) - Number(aValue);

      if (primary === num.ZERO) {
        const secondaryA = normalize(a['name'] || '');
        const secondaryB = normalize(b['name'] || '');

        return (
          handleNullOrUndefined(secondaryA, secondaryB) ||
          collator.compare(secondaryA, secondaryB)
        );
      }

      return primary;
    };

    // Helper: Compare numbers
    const compareNumbers = (aValue: number, bValue: number): number => {
      return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
    };

    return [...dataToSort].sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      // Handle null or undefined values
      const nullComparison = handleNullOrUndefined(aValue, bValue);
      if (nullComparison !== num.ZERO) {
        return nullComparison;
      }

      // Date comparison
      if (sortColumn === 'createdAt') {
        return compareDates(aValue, bValue);
      }

      // String comparison
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return compareStrings(aValue, bValue, a, b);
      }

      // Boolean comparison
      if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
        return compareBooleans(aValue, bValue, a, b);
      }

      // Numeric comparison
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return compareNumbers(aValue, bValue);
      }

      // Default: No sorting for unsupported types
      return num.ZERO;
    });
  };

  const handleExportCSV = () => {
    const customHeaders = {
      name: 'User Name',
      phone: 'Phone Number',
      createdAt: 'Registered On',
      userType: 'User Type',
      zipCode: 'Zip Code',
      gamePreferences: 'Game Preferences',
      isActive: 'Status',
    };

    // Generate a timestamp for the filename
    const now = new Date();
    const date = now.toISOString().split('T')[0]; // Extract the date part (YYYY-MM-DD)
    const time = now
      .toTimeString() // Get the time part
      .split(' ')[0] // Remove timezone
      .replace(/:/g, '-'); // Replace colons with dashes (HH-MM)

    const fileName = `filtered_user_list_${date}_${time}.csv`;

    // Get sorted data using the existing sorting function
    const sortedUsers = getSortedData();

    // Export the sorted users to CSV
    exportUsersToCSV(sortedUsers, fileName, customHeaders);
  };

  // add zip codes
  const addZipCode = (zipCode: string) => {
    if (!selectedZipCodes.includes(zipCode)) {
      setSelectedZipCodes([...selectedZipCodes, zipCode]);
    }
  };

  const removeZipCode = (zipToRemove: string) => {
    setSelectedZipCodes(selectedZipCodes.filter((zip) => zip !== zipToRemove));
  };

  const handleCheckboxChange = (zipCode: string) => {
    if (selectedZipCodes.includes(zipCode)) {
      setSelectedZipCodes(selectedZipCodes.filter((zip) => zip !== zipCode));
    } else {
      addZipCode(zipCode);
    }
  };

  const handleApplyFilter = () => {
    let filtered = [...users]; // Start with all users
    let filtersApplied = false;

    // Apply selectedUserTypes filter if applicable
    if (selectedUserTypes.length > num.ZERO) {
      if (
        selectedUserTypes.length === num.ONE &&
        selectedUserTypes.includes(USER_TYPES.CHARACTER)
      ) {
        // Filter users with userType = "Character"
        filtered = filtered.filter(
          (user) =>
            user.userType === USER_TYPES.CHARACTER &&
            user.userType != null &&
            user.userType !== ''
        );
      } else if (
        selectedUserTypes.length === num.ONE &&
        selectedUserTypes.includes(USER_TYPES.DUNGEON_MASTER)
      ) {
        // Filter users with userType = "Dungeon Master"
        filtered = filtered.filter(
          (user) =>
            user.userType === USER_TYPES.DUNGEON_MASTER &&
            user.userType != null &&
            user.userType !== ''
        );
      } else if (
        selectedUserTypes.length === num.TWO &&
        selectedUserTypes.includes(USER_TYPES.CHARACTER) &&
        selectedUserTypes.includes(USER_TYPES.DUNGEON_MASTER)
      ) {
        // Filter users with userType = "Character + Dungeon Master"
        filtered = filtered.filter(
          (user) =>
            user.userType === USER_TYPES.CHARACTER_AND_DM &&
            user.userType != null &&
            user.userType !== ''
        );
      }

      filtersApplied = true;
    }

    // Apply selectedGamePreferences filter if applicable
    if (selectedGamePreferences && selectedGamePreferences !== '') {
      filtered = filtered.filter(
        (user) =>
          (user.gamePreferences &&
            user.gamePreferences.includes(selectedGamePreferences)) ||
          (user.profile &&
            user.profile.some(
              (profile) =>
                profile.gameStyle &&
                profile.gameStyle.includes(selectedGamePreferences) &&
                profile.gameStyle !== '' &&
                profile.gameStyle != null
            ))
      );

      filtersApplied = true;
    }

    // Apply selectedZipCodes filter if applicable
    if (selectedZipCodes.length > num.ZERO) {
      filtered = filtered.filter(
        (item) => item.zipCode && selectedZipCodes.includes(item.zipCode)
      );

      filtersApplied = true;
    }

    // Update filtered users and close filter dialog
    if (filtersApplied) {
      setIsFiltered(true);
    }

    setFilteredUsers(filtered);
    setCurrentPage(num.ONE);
    handleCloseFilter();
  };

  const handletUserTypeChange = (
    setter: React.Dispatch<React.SetStateAction<string[]>>,
    value: string
  ) => {
    setter((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  const handleGamePreferencesChange = (
    setter: (value: string | undefined) => void,
    value: string
  ) => {
    setter(value); // Simply set the selected value
  };

  const handleClearFilters = () => {
    setFilteredUsers([]);
    setSelectedZipCodes([]);
    setSelectedUserTypes([]);
    setSelectedGamePreferences('');
    setIsFiltered(false);
    setCurrentPage(num.ONE);
  };

  return (
    <div className="admin-body-container">
      {loading && <Loader />}
      <Container fluid>
        <div className="table-layout-head">
          <div className="head-blank-col"></div>
          <div className="table-filter-row">
            <div
              className={`filter-btn ${isFiltered && 'active'}`}
              onClick={handleShowFilter}
            >
              <span className="icon-filter-icon"></span> Filter
            </div>
            <Dropdown align="end" className="table-dropdown">
              <Dropdown.Toggle
                variant="outline"
                className="action-btn"
                id="dropdown-custom-1"
              >
                <div className="filter-btn">
                  <span className="icon-export-icon"></span> Export
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="action-dropdown">
                <Dropdown.Item onClick={handleExportCSV}>
                  <Image src={Icons.csvIcon} alt="icon" />
                  Download CSV
                </Dropdown.Item>
                {/* <Dropdown.Item>Activate User</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <UserList
          data={getSortedData()} // Pass sorted data to UserList
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={setCurrentPage}
          onShowModal={handleShowModal} // Pass showModal callback to child
          onSort={handleSort}
          loading={loading}
        />

        <UserFilterModal
          show={showFilter}
          onClose={handleCloseFilter}
          selectedUserTypes={selectedUserTypes}
          setSelectedUserTypes={setSelectedUserTypes}
          selectedGamePreferences={selectedGamePreferences}
          setSelectedGamePreferences={setSelectedGamePreferences}
          selectedZipCodes={selectedZipCodes}
          zipcodes={zipcodes}
          handleGamePreferencesChange={handleGamePreferencesChange}
          handletUserTypeChange={handletUserTypeChange}
          handleCheckboxChange={handleCheckboxChange}
          removeZipCode={removeZipCode}
          handleClearFilters={handleClearFilters}
          handleApplyFilter={handleApplyFilter}
        />
      </Container>

      {/* Confirmation activate/deactivate user */}
      <CustomModal
        customClass=""
        show={showModal}
        onHide={() => setShowModal(false)}
        title={
          modalAction?.isActive === false
            ? 'Activate User?'
            : 'Deactivate User?'
        }
        bodyContent={modalMessages.userActivationMessage(modalAction)}
        footerContent={
          <>
            <button
              className="btn btn-danger"
              onClick={() => {
                if (modalAction) {
                  handleToggleStatus(modalAction.userId, modalAction.isActive);
                  setShowModal(false);
                }
              }}
            >
              Yes, {modalAction?.isActive === false ? 'activate' : 'deactivate'}
            </button>
            <button
              className="btn btn-transparent"
              onClick={() => setShowModal(false)}
            >
              Not now
            </button>
          </>
        }
      />
    </div>
  );
};

export default UserManagement;
