import parsePhoneNumberFromString from 'libphonenumber-js';

export const capitalize = (value: string) => {
  if (!value) return value;
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const formatPhoneNumber = (phone: string | undefined): string => {
  if (!phone) return '';
  const phoneNumber = parsePhoneNumberFromString(phone);
  return phoneNumber ? phoneNumber.formatInternational() : phone;
};

export const exportUsersToCSV = <T extends object>(
  data: T[],
  filename: string,
  customHeaders?: Partial<Record<keyof T, string>>
) => {
  if (!data || !data.length) return;

  const formatCreatedAt = (date: Date | undefined): string => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const formatIsActive = (isActive: boolean | undefined): string => {
    return isActive ? 'Active' : 'Inactive';
  };

  let csvContent: string;

  if (customHeaders) {
    const keys = Object.keys(customHeaders) as Array<keyof T>;
    const headers = keys.map((key) => customHeaders[key]);

    csvContent = [
      headers.join(','),
      ...data.map((row) =>
        keys
          .map((key) => {
            const value = row[key]; // Keep `value` readonly here
            let transformedValue: string;

            if (key === 'phone' && typeof value === 'string') {
              transformedValue = formatPhoneNumber(value);
            } else if (key === 'createdAt' && value instanceof Date) {
              transformedValue = formatCreatedAt(value);
            } else if (key === 'isActive' && typeof value === 'boolean') {
              transformedValue = formatIsActive(value);
            } else {
              transformedValue = String(value); // Fallback to string conversion
            }

            return `"${transformedValue}"`;
          })
          .join(',')
      ),
    ].join('\n');
  } else {
    const keys = Object.keys(data[0]) as Array<keyof T>;
    const headers = keys;

    csvContent = [
      headers.join(','),
      ...data.map((row) =>
        keys
          .map((key) => {
            const value = row[key]; // Keep `value` readonly here
            let transformedValue: string;

            if (key === 'phone' && typeof value === 'string') {
              transformedValue = formatPhoneNumber(value);
            } else if (key === 'createdAt' && value instanceof Date) {
              transformedValue = formatCreatedAt(value);
            } else if (key === 'isActive' && typeof value === 'boolean') {
              transformedValue = formatIsActive(value);
            } else {
              transformedValue = String(value); // Fallback to string conversion
            }

            return `"${transformedValue}"`;
          })
          .join(',')
      ),
    ].join('\n');
  }

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
