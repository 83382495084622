import { ReactNode } from 'react';
import { Image } from 'react-bootstrap';
import { Icons } from '../../constants/icons';

interface AuthLayoutProps {
  children: ReactNode;
  title: string;
}

function AuthLayout({ children, title }: AuthLayoutProps) {
  return (
    <div className="d-flex flex-column min-vh-100">
      <main className="admin-main-container">
        <div className="signin-container">
          <div className="login-banner">
            <Image src={Icons.bgImg} className="bg-img" />
            <div className="logo">
              <Image src={Icons.logoWhite} />
            </div>
          </div>
          <div className="signin-form-container">
            <div className="login-form-card">
              <div className="form-title">{title}</div>
              {children}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default AuthLayout;
