import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { RoutesConstants } from './RoutesConstants';
import Loader from '../components/loader/Loader';

function PublicRoute({ children }) {
  const { user, loading } = useAuth();

  if (loading) return <Loader />;

  return user ? <Navigate to={RoutesConstants.USER_MANAGEMENT} /> : children;
}

export default PublicRoute;
