import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { RoutesConstants } from '../../routes/RoutesConstants';
import Loader from '../loader/Loader';

const NotFound = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <Loader />;
  }

  if (user) {
    return <Navigate to={RoutesConstants.USER_MANAGEMENT} />;
  } else {
    return <Navigate to={RoutesConstants.LOGIN} />;
  }
};

export default NotFound;
