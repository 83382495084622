import { useState, useEffect } from 'react';
import { Container, Dropdown, Image, Modal } from 'react-bootstrap';
import FlagList from './FlagList';
import flagDataServiceInstance, {
  Report,
} from '../../../services/flag.service';
import CustomModal from '../../../components/modal/CustomModal';
import Loader from '../../../components/loader/Loader';
import { exportUsersToCSV } from '../../../utils/appUtils';
import { AppNumbers as num } from '../../../constants/appNumbers';
import { Icons } from '../../../constants/icons';
import { modalMessages } from '../../../constants/appStrings';

type SortOrder = 'asc' | 'desc';

const FlagManagement = () => {
  const [flagReports, setFlagReports] = useState<Report[]>([]);
  const [currentPage, setCurrentPage] = useState(num.ONE);
  const [itemsPerPage] = useState(num.TEN);
  const [loading, setLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState<keyof Report | null>(
    'reportedRefUserName'
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

  const [showDescription, setShowDescription] = useState<Report | null>(null);
  const handleClose = (): any => setShowDescription(null);
  const handleShowDescription = (flag: Report) => {
    setShowDescription(flag);
  };

  // resolve/unresolve status
  const [showStatusConfirmationModal, setShowStatusConfirmationModal] =
    useState(false);
  const [statusConfirmationModalAction, setStatusConfirmationModalAction] =
    useState<{
      id: string;
      status: string;
    } | null>(null);

  const fetchFlagReports = async () => {
    try {
      const data = await flagDataServiceInstance.getAllReportsWithUserDetails();

      setFlagReports(data);
    } catch (error) {
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFlagReports();
  }, []);

  const handleSort = (column: keyof Report) => {
    if (sortColumn === column) {
      // Toggle sort order
      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      // Set new column and reset to ascending order
      setSortColumn(column);
      setSortOrder('asc');
    }

    // Reset to the first page
    setCurrentPage(num.ONE);
  };

  const getSortedData = (): Report[] => {
    if (
      !sortColumn ||
      !flagReports.some((flag) => flag.hasOwnProperty(sortColumn))
    ) {
      return flagReports;
    }

    const collator = new Intl.Collator(undefined, { sensitivity: 'base' });

    return [...flagReports].sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      // Handle empty, null, or undefined values
      if (aValue == null || bValue == null) {
        if (aValue == null && bValue == null) {
          return num.ZERO;
        }
        if (aValue == null) {
          return sortOrder === 'asc' ? num.ONE : -num.ONE;
        } else {
          return sortOrder === 'asc' ? -num.ONE : num.ONE;
        }
      }

      // Date comparison
      if (sortColumn === 'createdAt') {
        const dateA = new Date(aValue);
        const dateB = new Date(bValue);

        return sortOrder === 'asc'
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      }

      // String comparison
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const normalize = (str: string) => str.trim().normalize('NFKC');
        const normalizedA = normalize(aValue);
        const normalizedB = normalize(bValue);

        const primary =
          sortOrder === 'asc'
            ? collator.compare(normalizedA, normalizedB)
            : collator.compare(normalizedB, normalizedA);

        if (primary === num.ZERO) {
          const secondaryA = (a['reportedRefUserName'] || '')
            .trim()
            .normalize('NFKC');
          const secondaryB = (b['reportedRefUserName'] || '')
            .trim()
            .normalize('NFKC');

          // Sort undefined or empty names to the end
          if (!secondaryA || !secondaryB) {
            if (!secondaryA && !secondaryB) {
              return num.ZERO;
            }
            if (!secondaryA) {
              return sortOrder === 'asc' ? num.ONE : -num.ONE;
            } else {
              return sortOrder === 'asc' ? -num.ONE : num.ONE;
            }
          }

          return collator.compare(secondaryA, secondaryB);
        }

        return primary;
      }

      // Numeric comparison
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
      }

      // Default: No sorting for unsupported types
      return num.ZERO;
    });
  };

  const handleShowStatusConfirmationModal = (id: string, status: string) => {
    setStatusConfirmationModalAction({ id, status });
    setShowStatusConfirmationModal(true);
  };

  const handleToggleStatus = async (id: string, status: string) => {
    setLoading(true);
    try {
      await flagDataServiceInstance.toggleIsResolved(id, status);
      fetchFlagReports();
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleExportCSV = () => {
    const customHeaders = {
      reportedRefUserName: 'User Name',
      reportedByUserName: 'Reported By',
      createdAt: 'Reported On',
      category: 'Reasons',
      status: 'status',
    };

    // Generate a timestamp for the filename
    const now = new Date();
    const date = now.toISOString().split('T')[0]; // Extract the date part (YYYY-MM-DD)
    const time = now
      .toTimeString() // Get the time part
      .split(' ')[0] // Remove timezone
      .replace(/:/g, '-'); // Replace colons with dashes (HH-MM)

    const fileName = `report_${date}_${time}.csv`;

    // Get sorted data using the existing sorting function
    const sortedUsers = getSortedData();

    // Export the sorted users to CSV
    exportUsersToCSV(sortedUsers, fileName, customHeaders);
  };

  return (
    <div className="admin-body-container">
      {loading && <Loader />}
      <Container fluid>
        <div className="table-layout-head">
          <div className="head-blank-col"></div>
          <div className="table-filter-row">
            <Dropdown align="end" className="table-dropdown">
              <Dropdown.Toggle
                variant="outline"
                className="action-btn"
                id="dropdown-custom-1"
              >
                <div className="filter-btn">
                  <span className="icon-export-icon"></span> Export
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="action-dropdown">
                <Dropdown.Item onClick={handleExportCSV}>
                  <Image src={Icons.csvIcon} alt="icon" />
                  Download CSV
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {/* /Table */}
        <FlagList
          data={getSortedData()}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={setCurrentPage}
          onSort={handleSort}
          showDescriptionModal={handleShowDescription}
          showStatusConfirmModal={handleShowStatusConfirmationModal}
          loading={loading}
        />

        <Modal
          className="modal-description"
          show={showDescription !== null ? true : false}
          onHide={handleClose}
          centered
        >
          <Modal.Body>
            {showDescription && showDescription.attachments && (
              <>
                <div className="description-title">Attachment</div>
                <div className="detail-img">
                  <Image src={showDescription.attachments || Icons.dummyUser} />
                </div>
              </>
            )}
            {showDescription && showDescription.description && (
              <>
                <div className="description-title">Description</div>
                <div className="description-detail">
                  {showDescription.description}
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      </Container>

      {/* Confirmation resolve/unresolve user */}
      <CustomModal
        customClass=""
        show={showStatusConfirmationModal}
        onHide={() => setShowStatusConfirmationModal(false)}
        title={
          statusConfirmationModalAction?.status === 'unresolved'
            ? 'Resolve Flag?'
            : 'Unresolve Flag?'
        }
        bodyContent={modalMessages.statusConfirmationMessage(
          statusConfirmationModalAction
        )}
        footerContent={
          <>
            <button
              className="btn btn-danger"
              onClick={() => {
                if (statusConfirmationModalAction) {
                  handleToggleStatus(
                    statusConfirmationModalAction.id,
                    statusConfirmationModalAction.status
                  );
                  setShowStatusConfirmationModal(false);
                }
              }}
            >
              Yes,{' '}
              {statusConfirmationModalAction?.status === 'unresolved'
                ? 'resolve'
                : 'unresolve'}
            </button>
            <button
              className="btn btn-transparent"
              onClick={() => setShowStatusConfirmationModal(false)}
            >
              Not now
            </button>
          </>
        }
      />
    </div>
  );
};

export default FlagManagement;
