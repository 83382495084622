import { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { ErrorMessage, Field, Formik } from 'formik';
import { loginSchema } from '../../utils/validationSchema';
import { InitialValues } from '../../constants/formInitialValues';
import { AppNumbers as num } from '../../constants/appNumbers';
import { ClassString, ValidationMessage } from '../../constants/appStrings';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../firebaseConfig';
import { toast } from 'react-toastify';
import AuthLayout from './AuthLayout';
import useDisallowFirstSpace from '../../hooks/useDisallowFirstSpace';
import { RoutesConstants } from '../../routes/RoutesConstants';
import LoadingButton from '../../components/button/Button';

function Login() {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  useDisallowFirstSpace(inputRef); // Apply the custom hook to disallow the first space
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const passwordToggle = () => setShowPassword(!showPassword);
  const getPasswordFieldType = () => (showPassword ? 'text' : 'password');

  const handleInput =
    (setFieldValue: any, fieldName: string) => (event: any) => {
      let { value } = event.target;

      if (value.startsWith(' ')) {
        value = value.trimStart();
      }

      // Update Formik field value
      setFieldValue(fieldName, value);
    };

  const submitForm = async (values: any, { resetForm }: any) => {
    setLoading(true);
    try {
      // Sign in the user
      const userCredential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = userCredential.user;

      // Check if the user has an admin role
      const userDocRef = doc(db, 'admin', user.uid); // Assuming "admin" collection and document ID is user UID
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists() && userDoc.data().role === 'admin') {
        navigate(RoutesConstants.USER_MANAGEMENT);
      } else {
        toast.error(ValidationMessage.accessDenied);
        await auth.signOut();
      }
    } catch (error: any) {
      if (error.code === 'auth/invalid-credential') {
        toast.error(ValidationMessage.invalidEmailPassword);
      } else if (error.code === 'auth/too-many-requests') {
        toast.error(ValidationMessage.tooManyRequest);
      } else {
        throw error;
      }
    }
    setLoading(false);

    // Reset the form after attempting login
    // resetForm();
  };

  return (
    <AuthLayout title="Sign In">
      <Formik
        validationSchema={loginSchema}
        onSubmit={submitForm}
        initialValues={InitialValues.login}
      >
        {({ handleSubmit, errors, touched, setFieldValue }) => (
          <Form className="login-form" noValidate onSubmit={handleSubmit}>
            <Form.Group
              controlId="formEmail"
              className={
                errors.email && touched.email
                  ? ClassString.inputError
                  : 'input-group'
              }
            >
              <Field
                type="email"
                placeholder="Email Address*"
                name="email"
                className="form-control"
                id="formEmail"
                maxLength={num.HUNDRED}
                innerRef={inputRef} // Attach the ref here to link it with the hook
                onInput={handleInput(setFieldValue, 'email')}
              />
              <ErrorMessage name="email" component="span" className="error" />
            </Form.Group>

            <Form.Group
              controlId="formPassword"
              className={
                'password-input ' +
                (errors.password && touched.password
                  ? ClassString.inputError
                  : 'input-group')
              }
            >
              <div className="input-with-icon">
                <Field
                  type={getPasswordFieldType()}
                  name="password"
                  className="form-control"
                  id="formPassword"
                  placeholder="Password*"
                />
                <div className="input-icon" onClick={passwordToggle}>
                  {showPassword ? 'Hide' : 'Show'}
                </div>
              </div>
              <ErrorMessage
                name="password"
                component="span"
                className="error"
              />
            </Form.Group>

            <LoadingButton
              loading={loading}
              buttonText="Sign in"
              variant="primary"
              className="w-100"
              type="submit"
            />
          </Form>
        )}
      </Formik>
      <div className="forgotPwd-link">
        <Link to={RoutesConstants.FORGOT_PASSWORD}>Forgot Password?</Link>
      </div>
    </AuthLayout>
  );
}

export default Login;
