import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface CustomModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  customClass: string;
  bodyContent: React.ReactNode;
  footerContent?: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({
  show,
  onHide,
  title,
  customClass,
  bodyContent,
  footerContent,
}) => {
  return (
    <Modal className={`modal-confirm ${customClass}`} show={show} onHide={onHide} backdrop="static" centered>
      <Modal.Body>
        <Modal.Title>{title}</Modal.Title>
        <div className="modal-subtitle">{bodyContent}</div>
        <div className="modal-btn-row">
          {footerContent || (
            <>
              <Button variant="secondary" onClick={onHide}>
                Ok
              </Button>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
