import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { ErrorMessage, Field, Formik } from 'formik';
import { resetPasswordSchema } from '../../utils/validationSchema';
import { InitialValues } from '../../constants/formInitialValues';
import { ClassString, ValidationMessage } from '../../constants/appStrings';
import AuthLayout from './AuthLayout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import { toast } from 'react-toastify';
import useDisallowFirstSpace from '../../hooks/useDisallowFirstSpace';
import { AppNumbers as num } from '../../constants/appNumbers';
import { RoutesConstants } from '../../routes/RoutesConstants';
import LoadingButton from '../../components/button/Button';

function ResetPassword() {
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const passwordToggle = () => setShowPassword(!showPassword);
  const getPasswordFieldType = () => (showPassword ? 'text' : 'password');

  const [actionCode, setActionCode] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const effectRan = useRef(false); // Flag to track if effect has already run

  // Apply separate refs for each input field
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  // Apply hook for each field
  useDisallowFirstSpace(passwordRef);
  useDisallowFirstSpace(confirmPasswordRef);

  const handleInput =
    (setFieldValue: any, fieldName: string) => (event: any) => {
      let { value } = event.target;

      if (value.startsWith(' ')) {
        value = value.trimStart();
      }

      // Update Formik field value
      setFieldValue(fieldName, value);
    };

  // Check if action code (reset token) is valid
  useEffect(() => {
    if (effectRan.current) return; // Prevent duplicate execution
    effectRan.current = true;

    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('oobCode');

    setLoading(true);
    if (code) {
      verifyPasswordResetCode(auth, code)
        .then(() => {
          setActionCode(code);
          setLoading(false);
        })
        .catch((error) => {
          toast.error(ValidationMessage.invalidOrExpiredResetLink);
          navigate(RoutesConstants.FORGOT_PASSWORD);
        });
    } else {
      toast.error(ValidationMessage.noResetLinkFound);
      navigate(RoutesConstants.FORGOT_PASSWORD);
    }
    setLoading(false);
  }, [location, navigate]);

  const submitForm = async (values: any, { resetForm }: any) => {
    setLoading(true);
    try {
      // Reset password using Firebase
      await confirmPasswordReset(auth, actionCode, values.password);
      toast.success(ValidationMessage.passwordResetSuccessfully);
      resetForm();
      navigate(RoutesConstants.LOGIN);
    } catch (error: any) {
      if (error.code === 'auth/invalid-action-code') {
        toast.error(ValidationMessage.resetLinkExpired);
      } else {
        throw error;
      }
    }
    setLoading(false);
  };

  return (
    <AuthLayout title="Reset Password.">
      <div className="form-subtitle reset-subtitle">
        You can add a new password to your profile.
      </div>
      <Formik
        validationSchema={resetPasswordSchema}
        onSubmit={submitForm}
        initialValues={InitialValues.resetPassword}
      >
        {({ handleSubmit, errors, touched, setFieldValue }) => (
          <Form className="login-form" noValidate onSubmit={handleSubmit}>
            <Form.Group
              controlId="password"
              className={
                errors.password && touched.password
                  ? ClassString.inputError
                  : 'input-group'
              }
            >
              <div className="input-with-icon">
                <Field
                  type={getPasswordFieldType()}
                  placeholder="Password*"
                  name="password"
                  className="form-control"
                  id="password"
                  maxLength={num.HUNDRED}
                  innerRef={passwordRef}
                  onInput={handleInput(setFieldValue, 'password')}
                />
                <div className="input-icon" onClick={passwordToggle}>
                  {showPassword ? 'Hide' : 'Show'}
                </div>
              </div>
              <div className="input-note">
                Minimum 8 characters, 1 special character, 1 uppercase
                character, must be alphanumeric.
              </div>
              <ErrorMessage
                name="password"
                component="span"
                className="error"
              />
            </Form.Group>

            <Form.Group
              controlId="confirmPassword"
              className={
                errors.confirmPassword && touched.confirmPassword
                  ? ClassString.inputError
                  : 'input-group'
              }
            >
              <Field
                type="password"
                placeholder="Confirm Password*"
                name="confirmPassword"
                className="form-control"
                id="confirmPassword"
                maxLength={num.HUNDRED}
                innerRef={confirmPasswordRef} // Attach the ref here to link it with the hook
                onInput={handleInput(setFieldValue, 'confirmPassword')}
              />
              <ErrorMessage
                name="confirmPassword"
                component="span"
                className="error"
              />
            </Form.Group>

            <LoadingButton
              loading={loading}
              buttonText="Update"
              variant="primary"
              type="submit"
            />
          </Form>
        )}
      </Formik>
      <div className="forgotPwd-link">
        <Link to={RoutesConstants.LOGIN}>Cancel</Link>
      </div>
    </AuthLayout>
  );
}

export default ResetPassword;
