import React from 'react';
import { Image } from 'react-bootstrap';
import { Icons } from '../../constants/icons';

interface NoResultFoundProps {
  title?: string;
  subtitle?: string;
}

const NoResultFound: React.FC<NoResultFoundProps> = ({
  title = 'No result found!',
  subtitle,
}) => {
  return (
    <div className="no-result-wrap">
      <Image src={Icons.noResultIcon} className="no-data-img" alt="icon" />
      <div className="no-data-title">{title}</div>
      {subtitle && <div className="no-data-subtitle">{subtitle}</div>}
    </div>
  );
};

export default NoResultFound;
