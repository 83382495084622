import { useEffect, useState } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { RoutesConstants } from '../../../routes/RoutesConstants';
import { useNavigate, useParams } from 'react-router-dom';
import userDataServiceInstance, { User } from '../../../services/user.service';
import Loader from '../../../components/loader/Loader';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';
import UserProfileInfo from './UserProfileInfo';
import Modal from '../../../components/modal/CustomModal';
import { AppNumbers as num } from '../../../constants/appNumbers';
import { Icons } from '../../../constants/icons';
import { modalMessages } from '../../../constants/appStrings';

const UserDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState<{
    userId: string;
    isActive: boolean | undefined;
  } | null>(null);

  const fetchUserById = async (userId: string) => {
    setLoading(true);
    try {
      const data = await userDataServiceInstance.fetchUserById(userId);
      setUser(data);
    } catch (error) {
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchUserById(id);
    }
  }, [id]);

  const handleToggleStatus = async () => {
    if (!modalAction) return;

    const { userId, isActive } = modalAction;

    setLoading(true);
    try {
      await userDataServiceInstance.toggleIsActive(userId, isActive);
      if (id) {
        fetchUserById(id);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    } finally {
      setShowModal(false);
      setModalAction(null);
    }
  };

  const openConfirmationModal = (
    userId: string,
    isActive: boolean | undefined
  ) => {
    setModalAction({ userId, isActive });
    setShowModal(true);
  };

  const handleDeleteProfile = async (documentId: string) => {
    setLoading(true);
    try {
      await userDataServiceInstance.deleteProfile(documentId);
      if (id) {
        fetchUserById(id);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return (
    <div className="admin-body-container">
      {loading && <Loader />}
      <Container fluid>
        <div className="user-detail-layout">
          <div className="user-detail-back">
            <div className="back-btn">
              <div
                className="back-icon"
                onClick={() => navigate(`${RoutesConstants.USER_MANAGEMENT}`)}
              >
                {/* <Image
                  src={backIcon}
                  alt="icon"
                /> */}
                <span className="icon-back-icon"></span>
              </div>
              <span>User Details</span>
            </div>
          </div>
          {user ? (
            <>
              <div className="user-detail-head">
                <div className="user-detail-info">
                  <div className={`user-img ${!user.profileImageUrl && 'p-2'}`}>
                    <Image src={user.profileImageUrl || Icons.dummyUser} />
                  </div>
                  <div>
                    <div className="user-info-list">
                      <div className="user-info-col">
                        <div className="info-label">Username</div>
                        <div className="info-data">{user.name}</div>
                      </div>
                      <div className="user-info-col">
                        <div className="info-label">Phone Number</div>
                        <div className="info-data">
                          {formatIncompletePhoneNumber(user.phone)}
                        </div>
                      </div>
                      <div className="user-info-col">
                        <div className="info-label">Registered on</div>
                        <div className="info-data">
                          {user.createdAt
                            ? user.createdAt.toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                              })
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="user-info-list">
                      <div className="user-info-col">
                        <div className="info-label">Personality Tags</div>
                        <div className="user-notes-list">
                          {user.personalityTags &&
                            user.personalityTags.length > num.ZERO &&
                            user.personalityTags.map((tag, index) => (
                              <div className="notes-item" key={index}>
                                {tag}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user-status-btn">
                  <Button
                    variant={user.isActive === false ? 'success' : 'danger'}
                    onClick={() =>
                      openConfirmationModal(user.id, user.isActive)
                    }
                  >
                    {user.isActive === false
                      ? 'Activate User'
                      : 'Deactivate User'}
                  </Button>
                </div>
              </div>
              <div className="user-char-row">
                <UserProfileInfo
                  profileData={user.profile}
                  onDeleteProfile={handleDeleteProfile}
                  loading={loading}
                />
              </div>
            </>
          ) : (
            !loading && <div className="no-data">No data available.</div>
          )}
        </div>
      </Container>

      {/* Confirmation activate/deactivate user */}
      <Modal
        customClass=""
        show={showModal}
        onHide={() => setShowModal(false)}
        title={
          modalAction?.isActive === false
            ? 'Activate User?'
            : 'Deactivate User?'
        }
        bodyContent={modalMessages.userActivationMessage(modalAction)}
        footerContent={
          <>
            <button
              className={`btn ${
                modalAction?.isActive === false ? 'btn-success' : 'btn-danger'
              }`}
              onClick={handleToggleStatus}
            >
              Yes, {modalAction?.isActive === false ? 'activate' : 'deactivate'}
            </button>
            <button
              className="btn btn-transparent"
              onClick={() => setShowModal(false)}
            >
              Not now
            </button>
          </>
        }
      />
    </div>
  );
};

export default UserDetails;
