import * as Yup from 'yup';
import { ValidationMessage } from '../constants/appStrings';
import { Regex } from '../constants/appConst';

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email(ValidationMessage.emailPattern)
    .required(ValidationMessage.email),
  password: Yup.string().required(ValidationMessage.passwordRequired),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email(ValidationMessage.emailPattern)
    .required(ValidationMessage.email),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required(ValidationMessage.passwordRequired)
    .matches(Regex.password, ValidationMessage.passwordPattern),

  confirmPassword: Yup.string()
    .trim()
    .required(ValidationMessage.confirmPassword)
    .oneOf([Yup.ref('password')], ValidationMessage.passwordNotMatched)
    .when('password', (password, schema) => {
      if (password && password.length > 0) {
        return schema.oneOf(
          [Yup.ref('password')],
          ValidationMessage.passwordNotMatched
        );
      }
      return schema; // If password is empty, do not add any validation
    }),
});
