import React from 'react';
import {
  Modal,
  Form,
  Button,
  InputGroup,
  DropdownButton,
  Dropdown,
  Badge,
} from 'react-bootstrap';
import { AppNumbers as num } from '../../../constants/appNumbers';
import { USER_TYPES } from '../../../constants/appStrings';

interface UserFilterModalProps {
  show: boolean;
  onClose: () => void;
  selectedUserTypes: string[]; // Multi-select support
  setSelectedUserTypes: React.Dispatch<React.SetStateAction<string[]>>; // Correct setter type
  selectedGamePreferences: string | undefined;
  setSelectedGamePreferences: (value: string | undefined) => void;
  selectedZipCodes: string[];
  zipcodes: string[];
  handleGamePreferencesChange: (
    setter: (value: string | undefined) => void,
    value: string
  ) => void;
  handletUserTypeChange: (
    setter: React.Dispatch<React.SetStateAction<string[]>>,
    value: string
  ) => void;
  handleCheckboxChange: (zipCode: string) => void;
  removeZipCode: (zipCode: string) => void;
  handleClearFilters: () => void;
  handleApplyFilter: () => void;
}

const UserFilterModal: React.FC<UserFilterModalProps> = ({
  show,
  onClose,
  selectedUserTypes,
  setSelectedUserTypes,
  selectedGamePreferences,
  setSelectedGamePreferences,
  selectedZipCodes,
  zipcodes,
  handleGamePreferencesChange,
  handletUserTypeChange,
  handleCheckboxChange,
  removeZipCode,
  handleClearFilters,
  handleApplyFilter,
}) => {
  return (
    <Modal className="modal-filter" show={show} onHide={onClose} centered>
      <Modal.Body>
        <div className="filter-title">User Type</div>
        <div className="filter-form">
          <div className="custom-radio">
            <Form.Check type="checkbox" id="char-profile">
              <Form.Check.Input
                name="character"
                type="checkbox"
                checked={selectedUserTypes.includes(USER_TYPES.CHARACTER)}
                onChange={() =>
                  handletUserTypeChange(
                    setSelectedUserTypes,
                    USER_TYPES.CHARACTER
                  )
                }
              />
              <Form.Check.Label>Character Profile</Form.Check.Label>
            </Form.Check>
            <Form.Check type="checkbox" id="master-profile">
              <Form.Check.Input
                name="dm"
                type="checkbox"
                checked={selectedUserTypes.includes(USER_TYPES.DUNGEON_MASTER)}
                onChange={() =>
                  handletUserTypeChange(
                    setSelectedUserTypes,
                    USER_TYPES.DUNGEON_MASTER
                  )
                }
              />
              <Form.Check.Label>Dungeon Master Profile</Form.Check.Label>
            </Form.Check>
          </div>
        </div>
        <div className="filter-title">Game Preferences</div>
        <div className="filter-form">
          <div className="custom-radio">
            <Form.Check type="radio" id="theme-mature">
              <Form.Check.Input
                name="theme"
                type="radio"
                checked={selectedGamePreferences === 'Mature Themes'}
                onChange={() =>
                  handleGamePreferencesChange(
                    setSelectedGamePreferences,
                    'Mature Themes'
                  )
                }
              />
              <Form.Check.Label>Mature Themes</Form.Check.Label>
            </Form.Check>
            <Form.Check type="radio" id="theme-family">
              <Form.Check.Input
                name="theme"
                type="radio"
                checked={selectedGamePreferences === 'Family Friendly'}
                onChange={() =>
                  handleGamePreferencesChange(
                    setSelectedGamePreferences,
                    'Family Friendly'
                  )
                }
              />
              <Form.Check.Label>Family Friendly</Form.Check.Label>
            </Form.Check>
          </div>
        </div>

        {/* zip code - start */}
        <div className="filter-form">
          <div className="zip-code-filter">
            {/* Dropdown to select multiple zip codes */}
            <InputGroup>
              <DropdownButton
                variant="outline-secondary"
                id="dropdown-basic-button"
                title="Zip Codes"
                drop="up-centered"
                autoClose="outside"
              >
                <div className="filter-items-wrap">
                  {zipcodes.map((zipCode) => (
                    <Dropdown.Item
                      as="div"
                      key={zipCode}
                      onClick={() => handleCheckboxChange(zipCode)}
                      disabled={selectedZipCodes.includes(zipCode)}
                    >
                      <Form.Check type="checkbox">
                        <Form.Check.Input
                          type="checkbox"
                          checked={selectedZipCodes.includes(zipCode)}
                          onChange={(e) => {
                            e.stopPropagation(); // Prevent dropdown from closing
                            handleCheckboxChange(zipCode);
                          }}
                        />
                        <Form.Check.Label>{zipCode}</Form.Check.Label>
                      </Form.Check>
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>
            </InputGroup>

            <div className="zip-codes-container">
              {selectedZipCodes.map((zip, index) => (
                <Badge key={index} pill className="zip-badge">
                  {zip}
                  <Button
                    variant="link"
                    className="cross-btn"
                    onClick={() => removeZipCode(zip)}
                    aria-label="Remove"
                  ></Button>
                </Badge>
              ))}
            </div>
          </div>
        </div>
        {/* zip code - end */}
        <div className="filter-btns">
          {(selectedZipCodes.length > num.ZERO ||
            selectedUserTypes.length > num.ZERO ||
            selectedGamePreferences) && (
            <div className="clear-btn" onClick={handleClearFilters}>
              Clear
            </div>
          )}
          <Button
            variant="danger"
            onClick={handleApplyFilter}
            disabled={
              selectedZipCodes.length === num.ZERO &&
              selectedUserTypes.length === num.ZERO &&
              !selectedGamePreferences
            }
          >
            Apply
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserFilterModal;
