import { useEffect, RefObject } from 'react';
import { AppNumbers as num } from '../constants/appNumbers';

const useDisallowFirstSpace = (inputRef: RefObject<HTMLInputElement>) => {
  useEffect(() => {
    if (!inputRef?.current) {
      return;
    }

    const inputElement = inputRef.current;

    const handleKeyDown = (event: KeyboardEvent) => {
      const currentValue = inputElement.value;
      // Prevent entering a space as the first character
      if (event.key === ' ' && currentValue.length === num.ZERO) {
        event.preventDefault();
      }
    };

    // Attach event listener for keydown only
    inputElement.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on unmount
    return () => {
      inputElement.removeEventListener('keydown', handleKeyDown);
    };
  }, [inputRef]); // Trigger only if inputRef changes
};

export default useDisallowFirstSpace;
