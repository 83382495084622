import logoBlack from '../assets/images/logo-admin.svg';
import adminProfile from '../assets/images/admin-profile.svg';
import csvIcon from '../assets/images/csv-icon.svg';
import dummyUser from '../assets/images/dummy-user.svg';
import noCharImg from '../assets/images/no-profile-img.svg';
import actionIcon from '../assets/images/action-icon.svg';
import bgImg from '../assets/images/signin-bg.svg';
import logoWhite from '../assets/images/logo-white.svg';
import noResultIcon from '../assets/images/no-result.svg';

export const Icons = {
  logoBlack,
  adminProfile,
  csvIcon,
  dummyUser,
  noCharImg,
  actionIcon,
  bgImg,
  logoWhite,
  noResultIcon,
};
