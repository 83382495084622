import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import PublicRoute from './routes/PublicRoute';
import ProtectedRoute from './routes/ProtectedRoute';
import { RoutesConstants } from './routes/RoutesConstants';
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import UserManagement from './pages/admin/userManagement';
import FlagManagement from './pages/admin/flagManagement';
import NotFound from './components/notFound/NotFound';
import UserDetails from './pages/admin/userManagement/UserDetails';

function App() {
  return (
    <AuthProvider>
      <Routes>
        {/* Public Routes */}
        <Route
          path={RoutesConstants.LOGIN}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path={RoutesConstants.FORGOT_PASSWORD}
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path={RoutesConstants.RESET_PASSWORD}
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />

        {/* Protected Routes */}
        <Route
          path={RoutesConstants.USER_MANAGEMENT}
          element={
            <ProtectedRoute>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutesConstants.USER_DETAILS}
          element={
            <ProtectedRoute>
              <UserDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutesConstants.FLAG_MANAGEMENT}
          element={
            <ProtectedRoute>
              <FlagManagement />
            </ProtectedRoute>
          }
        />

        {/* Not Found Routes */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
